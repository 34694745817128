import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import CustomProgressBar from "./components/CustomProgressBar";
import AppRoutes from "./Router"; // Import the routes

const App = () => {
  useEffect(() => {
    const addSpanToGlobalBtn = () => {
      const globalBtns = document.querySelectorAll(".globalanimatedbtn");
      globalBtns.forEach((globalBtn) => {
        const currentSpanCount = globalBtn.querySelectorAll("span").length;
        const spanToAdd = 3 - currentSpanCount;
        if (spanToAdd > 0) {
          for (let i = 0; i < spanToAdd; i++) {
            const spanElement = document.createElement("span");
            globalBtn.appendChild(spanElement);
          }
        }
      });
    };

    // Initial span addition
    addSpanToGlobalBtn();

    // Event listener for DOM updates (navigation)
    const observer = new MutationObserver(() => {
      addSpanToGlobalBtn();
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Router>
      <Header />
      <ScrollToTop />
      <CustomProgressBar />
      <AppRoutes /> {/* Use the separated routes */}
      <Footer />
    </Router>
  );
};

export default App;
