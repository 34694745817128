import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import image1carouselmobile from './images/Hero-Image/innovapixelhero.webp';
import 'react-lazy-load-image-component/src/effects/blur.css';


export const HomeHero = () => {
  const imgElementsRef = useRef([]);

  useEffect(() => {
    imgElementsRef.current = document.querySelectorAll('.img_work_featured .imageAndContentMain');

    // Function to add a class after a delay
    const addClassWithDelay = (element, className, delay) => {
      setTimeout(() => {
        element.classList.add(className);
      }, delay);
    };

    // Function to remove a class after a delay
    const removeClassWithDelay = (element, className, delay) => {
      setTimeout(() => {
        element.classList.remove(className);
      }, delay);
    };

    // Function to perform the animation loop
    const animateWithDelayLoop = () => {
      const totalAnimationDuration = imgElementsRef.current.length * 1000;

      imgElementsRef.current.forEach((element, index) => {
        addClassWithDelay(element, 'animated', index * 1000);

        setTimeout(() => {
          removeClassWithDelay(element, 'animated', 0);
        }, totalAnimationDuration + 10000); // Additional 10 seconds after individual animations
      });
    };

    // Start the animation loop after 6 seconds
    setTimeout(() => {
      animateWithDelayLoop();

      // Start the animation loop at regular intervals
      const animationInterval = setInterval(() => {
        animateWithDelayLoop();
      }, (imgElementsRef.current.length * 1000 + 6000) * 2); // Twice the duration of one loop for safety

      // Clear the interval when the component is unmounted
      return () => clearInterval(animationInterval);
    }, 3000); // 6 seconds


  }, []);

  return (
    <div className="flex-hero-chrome">
      <div className="container-max">
        <div className="flex-left-home fiftypercnt">
          <h1>Shopify Custom Store Development Agency</h1>
          <p>Our skilled team delivers each e-commerce project with precision and expertise. Innovation is at our core, ensuring every Shopify store we create meets and exceeds expectations.</p>
          <Link to="/Work" className="globalanimatedbtn">Check out our work</Link>
          <div class="ratin-stars-cstm">
          <div class="ratin-stars-cstm-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
              <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path>
            </svg>
          </div>
          <span>4.9/5 Rate By 130+ Brands</span>
        </div>
        </div>

        <div className="right-empty-hero fiftypercnt">
          <img src={image1carouselmobile} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
