import React from 'react';
import imgbx1 from '../components/images/Awards/shopify-partner-1.png';
import imgbx2 from '../components/images/Awards/clutch.jpg';
import imgbx3 from '../components/images/Awards/designrush.png';
import imgbx4 from '../components/images/Awards/google.png';

const imgbx = [
  {
    link: '#',
    imageBoxes: imgbx1,
  },
    {
      link: 'https://clutch.co/profile/innovapixels',
      imageBoxes: imgbx2,
    },
    {
      link: 'https://www.designrush.com/agency/profile/innovapixels',
      imageBoxes: imgbx3,
    },
    {
      link: '#',
      imageBoxes: imgbx4,
    },
  
  ];

const Awards = () => {
  return (
    <div className='Section_awards'>
        <div className='container-max'>
          <h2 className='recent-work r2'>Awards &<span>Recognition</span></h2>
          <div className='main_awards_div_flex'>
            {imgbx.map((item, index) => (
                <a target="blank" className='award-single-box' href={item.link} key={index}>
                  <img src={item.imageBoxes} alt="img" />
                </a>
            ))}
          </div>
        </div>
      </div>
  )
}

export default Awards