import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from '@sanity/block-content-to-react';

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(5); // Number of initially visible posts
  const [activeCategory, setActiveCategory] = useState(null); // Active category
  const [loading, setLoading] = useState(true); // Loading state
  const [color, setColor] = useState(false);

  useEffect(() => {
    document.body.classList.add('home-page-body');
    return () => {
      document.body.classList.remove('home-page-body');
    };
  }, []);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (selectedCategory) {
        sanityClient
          .fetch(
            `*[_type == "post" && references($category)]{
              title,
              slug,
              "category": categories[]->title,
              publishedAt,
              body[]{
                ...,
                _type == 'image' => {
                  ...,
                  asset->{
                    url
                  }
                }
              },
              mainImage{
                asset->{
                  _id,
                  url
                }
              }
            }`,
            { category: selectedCategory._id }
          )
          .then((data) => {
            setAllPosts(data);
            setLoading(false);
          })
          .catch(console.error);
      } else {
        sanityClient
          .fetch(
            `*[_type == "post"]{
              title,
              slug,
              "category": categories[]->title,
              publishedAt,
              body[]{
                ...,
                _type == 'image' => {
                  ...,
                  asset->{
                    url
                  }
                }
              },
              mainImage{
                asset->{
                  _id,
                  url
                }
              }
            }`
          )
          .then((data) => {
            setAllPosts(data);
            setLoading(false);
          })
          .catch(console.error);
      }
    }, 1000); // 2 seconds delay

    return () => clearTimeout(timer);
  }, [selectedCategory, categories]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "category"]{
        _id,
        title
      }`)
      .then((data) => {
        setCategories(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const searchPosts = () => {
      if (searchQuery.trim() !== '') {
        const filteredPosts = allPostsData.filter(post =>
          post.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSearchResults(filteredPosts);
      } else {
        setSearchResults([]);
      }
    };

    searchPosts();
  }, [searchQuery, allPostsData]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setActiveCategory(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLoadMore = () => {
    setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 4);
  };

  return (
    <div className={color ? "main-div-blogs-page Section-1-Our-Work container-max Sticky_Barr" : "main-div-blogs-page Section-1-Our-Work container-max"}>
      <div className="sidebar">
        <div className="pos-sticky">
          <input
            type="text"
            placeholder="Search posts..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <ul>
            <li onClick={() => handleCategoryClick(null)} className={activeCategory === null ? 'active' : ''}>All</li>
            {categories.map((category) => (
              <li key={category._id} onClick={() => handleCategoryClick(category)} className={activeCategory && activeCategory._id === category._id ? 'active' : ''}>
                {category.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="All_Blogs_Main">
        {loading ? (
          <div className="loading-animation">
            <div className="loadingwrapper">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="shadow"></div>
              <div className="shadow"></div>
              <div className="shadow"></div>
              <span>Loading</span>
            </div>
          </div>
        ) : (
          (searchQuery.trim() !== '' ? searchResults : allPostsData).slice(0, visiblePosts).map((post) => (
            <Link to={"/" + post.slug.current} key={post.slug.current}>
              <span>
                <div>
                  <img src={post.mainImage.asset.url} alt="" />
                  <p>{post.category}</p>
                  <p>Published: {new Date(post.publishedAt).toLocaleDateString()}</p>
                </div>
                <span>
                  <h2>{post.title}</h2>
                  <BlockContent blocks={post.body} />
                </span>
              </span>
            </Link>
          ))
        )}
        {visiblePosts < (searchQuery.trim() !== '' ? searchResults : allPostsData).length && (
          <button onClick={handleLoadMore}>Load More</button>
        )}
      </div>
    </div>
  );
}
