import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Work from './Work';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import AllPosts from './components/AllPosts';
import OnePost from './components/OnePost';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/Work" element={<Work />} />
    <Route path="/Services" element={<Services />} />
    <Route path="/About" element={<About />} />
    <Route path="/Contact" element={<Contact />} />
    <Route path="/Blogs" element={<AllPosts />} />
    <Route path="/:slug" element={<OnePost />} />
  </Routes>
);

export default AppRoutes;
