import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import SanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '@sanity/block-content-to-react'

const builder = imageUrlBuilder(SanityClient)

const urlFor = (source) => {
    return builder.image(source)
}

const OnePost = () => {
    const [postData, setPostData] = useState(null)
    const { slug } = useParams()
    const navigate = useNavigate()

    const goBack = () => navigate('/Blogs')


    useEffect(() => {
        // Add the desired class to the body element when the component mounts
        document.body.classList.add('home-page-body');

        // Remove the class when the component unmounts (optional but recommended)
        return () => {
            document.body.classList.remove('home-page-body');
        };
    }, []);


    useEffect(() => {
        SanityClient.fetch(
            `*[slug.current == $slug]{
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                body[]{
                    ...,
                    _type == 'image' => {
                        ...,
                        asset->{
                            url
                        }
                    }
                },
                "name": author->name,
                "authorImage": author->image
            }`,
            { slug }
        )
            .then((data) => setPostData(data[0]))
            .catch(err => console.error(err))
    }, [slug])

    if (!postData) return <div>Loading...</div>

    return (
        <div className="Single_Blog_Main container-max Section-1-Our-Work">
            <div className="">
                <img className="" src={urlFor(postData.mainImage).url()} alt="" style={{ height: "400px" }} />
                <h2 className="Single_Post_Title">{postData.title}</h2>
            </div>
            <div className="Single_Post_Description">
                <BlockContent blocks={postData.body} />
            </div>



            <div className="Single_Post_Author_ImgAndName">
                <img src={urlFor(postData.authorImage).url()} className="" alt="" />
                <h4 className="">{postData.name}</h4>
            </div>




            <button className='' onClick={goBack}>
                All Posts
            </button>
        </div>
    )
}

export default OnePost
