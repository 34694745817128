import React, { useEffect } from "react";
import Contacts4 from './components/contact';
import ContactDetails from './components/ContactDetails';
import bgImg from './components/images/bg-img.webp';

const bggcontact = [
  {
    background: `url(${bgImg})`,
  }
];
const conactdetailsconstin = [
  {
    addresstitle: 'DROP US A LINE',
    phone: 'PHONE',
    tel: '',
    text1: 'GENERAL',
    text2: 'info@innovapixels.com',
    text3: 'CAREERS',
    text4: 'careers@innovapixels.com',
    text5: 'PRESS & MEDIA',
    text6: 'media@innovapixels.com',
  },

];

const conactdetailsconstin2 = [
  {
    StateTitle: 'west yorkshire',
    AddressLink: 'https://maps.app.goo.gl/qf5N7XuCRzufVKWUA',
    AddressTitle: '5 Villa Garden Warwick Road West Yorkshire Batley',
    DirectionLink: 'https://maps.app.goo.gl/qf5N7XuCRzufVKWUA',
    DirectionTitle: 'GET DIRECTIONS',

  }
];

const conactdetailsconstin3 = [
  // {
  //   StateTitle:'ALLENTOWN, PA',
  //   AddressLink: 'https://goo.gl/maps/H1NAPCHtVYKPEvv47',
  //   AddressTitle: '612 Hamilton Street Floor 6 Allentown, PA 18101',
  //   DirectionLink: 'https://goo.gl/maps/H1NAPCHtVYKPEvv47',
  //   DirectionTitle: 'GET DIRECTIONS',

  // }
];

const Contact = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only on mount

  useEffect(() => {
    document.body.classList.add('contact-page');
    return () => {
      document.body.classList.remove('contact-page');
    };
  }, []);


  return (
    <div>
      <div className='contact-us-page'>
        <Contacts4 contactbg={bggcontact} />
      </div>
      <ContactDetails ContactDetails={conactdetailsconstin} ContactDetails2={conactdetailsconstin2} ContactDetails3={conactdetailsconstin3} />

    </div>
  )
}

export default Contact